// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
  background: #ff0021;
  display: flex !important;
  padding: 10px;
  justify-content: center !important;
}

.header-text {
  color: white !important;
  font-weight: 700 !important;
  font-size: 25px !important;
}

.icon {
  font-size: 25px !important;
  color: #C4C4C4 !important;
  margin-right: 20px !important;
}

.log-out {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
}

.log-out-btn {
  width: 200px !important;
  height: 40px !important;
  border-radius: 30px !important;
  background: #CE4F53 !important;
  font-size: 17px !important;
  color: white !important;
}

.tab-text {
  flex: 1 1;
  font-weight: 300 !important;
  font-size: 20px !important;
}

.alert-container {
  margin: 10px 20px;
  background: rgba(0, 106, 176, 0.14);
  padding: 10px;
  border-radius: 5;
}

.verify {
  flex: 1 1;
  padding: 5px 10px;
  border-radius: 15;
  margin: 0px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/screens/Phone/Phone.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,wBAAA;EACA,aAAA;EACA,kCAAA;AACF;;AACA;EACE,uBAAA;EACA,2BAAA;EACA,0BAAA;AAEF;;AAEA;EACE,0BAAA;EACA,yBAAA;EACA,6BAAA;AACF;;AACA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,iBAAA;AAEF;;AAAA;EACE,uBAAA;EACA,uBAAA;EACA,8BAAA;EACA,8BAAA;EACA,0BAAA;EACA,uBAAA;AAGF;;AAAA;EACE,SAAA;EACA,2BAAA;EACA,0BAAA;AAGF;;AADA;EACE,iBAAA;EACA,mCAAA;EACA,aAAA;EACA,gBAAA;AAIF;;AAFA;EACE,SAAA;EACF,iBAAA;EACA,iBAAA;EACA,gBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AAKA","sourcesContent":[".header {\n  background:  #ff0021;\n  display: flex !important;\n  padding: 10px;\n  justify-content: center !important;\n}\n.header-text{\n  color: white !important ;\n  font-weight: 700 !important;\n  font-size: 25px !important;\n}\n\n\n.icon{\n  font-size: 25px !important;\n  color: #C4C4C4 !important;\n  margin-right: 20px !important;\n}\n.log-out{\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  padding-top: 10px;\n}\n.log-out-btn{\n  width: 200px !important;\n  height: 40px !important;\n  border-radius: 30px !important;\n  background:#CE4F53 !important;\n  font-size: 17px !important; \n  color: white !important;\n}\n\n.tab-text{\n  flex: 1;\n  font-weight: 300 !important;\n  font-size: 20px !important;\n}\n.alert-container{\n  margin: 10px 20px;\n  background: rgba(0, 106, 176,0.14);\n  padding: 10px;\n  border-radius: 5;\n}\n.verify{\n  flex: 1;\npadding: 5px 10px;\nborder-radius: 15;\nmargin: 0px 10px;\ndisplay: flex;\njustify-content: center;\nalign-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
