// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hidden-information {
  font-size: 20px !important;
  color: #fff !important;
  padding: 10px 10px !important;
}

.information {
  font-size: 17 !important;
  color: #fff !important;
  padding: 0px 10px !important;
}`, "",{"version":3,"sources":["webpack://./src/components/CreditCard.scss"],"names":[],"mappings":"AAAA;EACI,0BAAA;EACA,sBAAA;EACA,6BAAA;AACJ;;AACA;EACI,wBAAA;EACA,sBAAA;EACA,4BAAA;AAEJ","sourcesContent":[".hidden-information{\n    font-size: 20px !important; \n    color: #fff !important;  \n    padding: 10px 10px !important;\n}\n.information{\n    font-size: 17 !important; \n    color: #fff !important; \n    padding: 0px 10px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
